@import '@assets/styles/variables.scss';

.main {
  display: flex;
  flex-direction: row;
  font-family: $font-default;
  position: relative;

  @media only screen and (min-width: 1200px) {
    height: 100vh;
    width: 100%;
  }

  &.bg-color {
    background-color: $color-white;
  }

  &.bg-image {
    background-size: cover !important;

    &:after {
      content: '';
      background-color: $color-blue-dark-5;
      opacity: 0.99;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
}
