@import './variables.scss';

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.page {
  position: relative;
  width: 100%;
  z-index: 1;

  .content {
    color: $color-white;
    margin-left: 525px;
    max-width: 800px;
    padding: 60px 80px;
    white-space: pre-line;

    h2 {
      color: $color-white;
      font-size: 24px;
      letter-spacing: 0.67px;
      line-height: 33px;
      margin-bottom: 30px;
    }

    h4 {
      color: $color-white;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px;
      text-decoration: underline;
    }
  }
}

.page-no-access {
  background: $color-blue-dark-5 url('../img/bg_onboarding_process.png') no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 30px;
  position: relative;

  .container {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 15vh auto 0;
    max-width: 800px;
    padding: 20px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    svg {
      fill: $color-white;
    }

    .message {
      border-top: 1px solid $color-white;
      margin-top: 2%;
      color: inherit;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;

      @media only screen and (min-width: 768px) {
        border-left: 1px solid $color-white;
        margin-left: 2%;
        border-top: none;
        margin-top: 0;
        font-size: 20px;
        padding-left: 10px;
      }

      @media only screen and (min-width: 768px) {
        max-width: 90%;
        font-size: 30px;
      }
    }
  }
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

a {
  color: $color-blue;
}

p {
  margin-bottom: 12px;
}

ul {
  padding-left: 15px;
}

.form {
  > div {
    margin-bottom: 25px;
  }
}

.btn-link {
  height: auto;
  padding: 0;
  text-decoration: underline;
}

.main-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(99, 99, 99, 0.5);
}

.page-loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

/// Mantine
.mantine-TextInput-root {
  position: relative;

  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -10px;
  }
}

.mantine-Select-label,
.mantine-TextInput-label {
  color: $color-white;
  font-size: 14px;
  letter-spacing: 0.39px;
  margin-bottom: 10px;
}

.mantine-TextInput-input:disabled,
.mantine-Select-input:disabled {
  background-color: $color-white;
  color: $color-black;
  opacity: 0.7;
}

.mantine-Button-subtle {
  &:hover {
    background-color: transparent;
    color: lighten($color-blue, 10%);
  }
}

.mantine-Divider-withLabel {
  margin: 0 0 20px;

  .mantine-Divider-label {
    color: $color-white;
    font-family: inherit;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
}

/// react-phone-input-2
.react-tel-input {
  .country-list {
    color: $color-black !important;
  }

  .form-control {
    background-color: $color-white !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    height: 36px !important;
    width: 100% !important;

    &:disabled,
    &:disabled + .flag-dropdown {
      opacity: 0.7;
    }
  }
}
