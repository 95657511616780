@import '@assets/styles/variables.scss';

.page-login {
  align-items: center;
  background: $color-blue-dark-5 url('../../assets/img/bg_onboarding_process.png') no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .login {
    align-items: center;
    background: url('../../assets/img/bg_onboarding_process.png') no-repeat left top;
    background-color: $color-grey-middle-5;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    margin-top: -160px;
    min-height: 240px;
    overflow: hidden;
    padding: 38px 60px 32px;
    position: relative;
    z-index: 1;

    &-greeting {
      color: $color-white;
      display: flex;
      flex-direction: column;
      font-family: inherit;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: center;

      strong {
        font-weight: 600;
      }
    }

    &-link {
      font-size: 20px;
      margin-top: 20px;
    }
  }
}
