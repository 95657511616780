@import '@assets/styles/variables.scss';

.dropzone {
  gap: 12px;

  &-field {
    background-color: $color-blue-dark-3;
    border: 3px dashed $color-green-dark-1;
    min-width: 280px;
    position: relative;

    &:hover {
      background-color: transparent;
    }
  }

  &-file {
    gap: 10px;
  }

  &-inner {
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: 0.39px;
    pointer-events: none;

    > div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    span {
      text-decoration: underline;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.39px;
  }

  &-replace {
    font-size: 14px;
    padding: 0;
    text-decoration: underline;
  }
}
