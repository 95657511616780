@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.sidebar {
  background: url('../../assets/svg/logos/UNLogo.svg') no-repeat 140px center;
  background-color: $color-blue-dark-2;
  border-radius: 0 0 $border-radius-lg 0;
  color: $color-white;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px;
  white-space: pre-line;
  overflow: auto;

  @media only screen and (min-width: 768px) {
    padding: 40px;
    padding-top: 20px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 35%;
    max-height: 90%;
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    @media only screen and (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media only screen and (min-width: 1200px) {
      margin-bottom: 60px;
    }

    .logo {
      align-self: flex-start;
      width: 145px;
    }

    .info-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      margin-top: 20px;
    }

    .info {
      color: $color-blue-middle-1;
      font-size: 12px;
      line-height: 1.3;
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;

      .time {
        @extend .flex-row;
        color: $color-white;
        font-size: 20px;

        &-icon {
          margin-right: 10px;
        }
      }
    }

    .lang {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-size: 10px;
        height: auto;
        padding: 4px;

        &.active {
          color: $color-white;
        }
      }
    }
  }

  &-footer {
    @extend .flex-row;
    justify-content: space-between;
    margin-top: auto;

    .info {
      font-size: 12px;
    }

    .btn-logout {
      font-size: 12px;
      height: auto;
      margin-left: 12px;
      padding: 0;
      text-decoration: underline;
    }
  }

  h1 {
    color: $color-white;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.94px;
    line-height: 46px;
    margin-bottom: 24px;
  }

  h2 {
    color: $color-white;
    font-size: 24px;
    letter-spacing: 0.67px;
    line-height: 33px;
    margin-bottom: 12px;
  }

  h4 {
    color: $color-grey-light-1;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    text-decoration: underline;
  }

  p {
    color: $color-grey-light-1;
    font-size: 16px;
  }

  dl {
    background-color: $color-blue-middle-1;
    margin: 30px 0;
    margin-left: -$sidebar-padding;
    margin-right: -$sidebar-padding;
    padding: 12px 30px 25px 38px;

    dt {
      color: $color-white;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.44px;
      line-height: 22px;
      margin-bottom: 12px;
    }

    dd {
      align-items: flex-start;
      color: $color-white;
      display: flex;
      font-size: 16px;
      letter-spacing: 0.44px;
      line-height: 22px;
      margin-bottom: 22px;

      .icon-info {
        display: inline-block;
        fill: $color-white;
        height: 20px;
        margin-right: 12px;
        margin-top: 1px;
        min-width: 20px;
        width: 20px;
      }
    }
  }
}
