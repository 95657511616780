@import '@assets/styles/variables.scss';

.page-not-found {
  background: url('../../assets/svg/logos/UNLogo.svg') no-repeat;
  background-attachment: fixed;
  background-color: $color-blue-dark-1;
  background-position: bottom 5% right 5%;
  background-attachment: fixed;
  background-size: 380px;
  min-height: 100vh;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 40px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 60px;
  }

  .container {
    position: relative;
    transition: left 0.5s ease-out, opacity 0.5s ease-out;

    @media only screen and (min-width: 768px) {
      margin-top: 60px;
      max-width: 50%;
    }

    @media only screen and (min-width: 1200px) {
      margin-top: 120px;
      max-width: 40%;
    }

    h1 {
      color: $color-white;
      font-size: 36px;
      line-height: 46px;
    }

    hr {
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
      height: 1px;
      margin: 35px 0;
    }

    p {
      color: $color-white;
      font-size: 18px;
      line-height: 27px;
      transition: color 0.2s linear;
    }

    .links {
      display: flex;
      margin-top: 45px;

      .link {
        border: 2px solid rgba(255, 255, 0, 1) !important;
        border-radius: 50px;
        color: rgba(255, 255, 0, 1) !important;
        display: inline-block;
        font-size: 12px;
        padding: 8px 10px;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.2s linear, border-color 0.2s linear;

        @media only screen and (min-width: 768px) {
          font-size: 14px;
          padding: 8px 30px;
        }

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
