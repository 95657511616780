// Colors
$color-black: #000;
$color-blue: #3a89f7;
$color-white: #fff;
$color-yellow: #ffdd00;

$color-blue-dark-1: #08558b;
$color-blue-dark-2: #0d3e60;
$color-blue-dark-3: #284253;
$color-blue-dark-4: #152a3c;
$color-blue-dark-5: #081f2f;
$color-blue-middle-1: #3e8dc6;
$color-green-dark-1: #5f9e16;
$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #374d5d;
$color-grey-light-1: #d9e0eb;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #4e4e4e;
$color-grey-middle-4: #4e4e4e;
$color-grey-middle-5: #2a2d34;
$color-red-light-1: #f05b5b;

// Fonts
$font-default: 'OpenSans', 'Helvetica Neue', sans-serif;

// Miscellaneous
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;

$sidebar-padding: 2.8rem;
