@import '@assets/styles/variables.scss';

.stepper {
  font-family: inherit;

  &-content {
    font-family: inherit;
  }

  .steps {
    font-family: inherit;
    margin-bottom: 15px;

    .step {
      background-color: $color-grey-dark-3;
      border: 1px solid $color-blue-dark-4;
      font-family: inherit;
      height: 32px;
      position: relative;

      &[data-completed='true'],
      &[data-progress='true'] {
        background-color: $color-blue-middle-1;

        &::after {
          border-left: 12px solid $color-blue-middle-1;
        }
      }

      &:not(:first-child) {
        padding-left: 12px;
      }

      &::after {
        content: '';
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 12px solid $color-grey-dark-3;
        display: block;
        height: 0;
        margin-top: -15px;
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        z-index: 2;
      }

      &::before {
        content: '';
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 13px solid $color-blue-dark-4;
        display: block;
        height: 0;
        position: absolute;
        margin-top: -16px;
        margin-left: 1px;
        left: 100%;
        top: 50%;
        width: 0;
        z-index: 1;
      }

      &:not([data-progress='true']) {
        .step-icon {
          margin: 0 8px;
        }
        .step-body {
          display: none;
        }
      }

      &-wrap {
        margin: 0;
      }

      &-icon {
        background-color: transparent;
        border: none;
        color: $color-white;
        font-size: 12px;
        font-weight: 400;
        height: 26px;
        min-width: 26px;
        width: auto;
      }

      &-body {
        margin: 0 12px 0 0;
      }

      &-label {
        color: $color-white;
        font-size: 12px;
      }
    }

    .separator {
      display: none;
    }
  }
}
