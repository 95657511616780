@import '@assets/styles/variables.scss';
.btn-remove {
  border-color: $color-red-light-1;
  color: $color-red-light-1;
  margin-right: 10px;
}

.content {
  .step-welcome {
    width: 100%;

    @media only screen and (min-width: 1200px) {
      margin-top: 125px;
    }

    h2 {
      max-width: 340px;
    }

    ul {
      margin-bottom: 28px;

      li {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 12px;
      }
    }

    button {
      max-width: 442px;
    }
  }

  .step-organisation {
    .form {
      animation: slideLeft 0.3s linear;
      max-width: 442px;
      transition: 0.3s ease-out;

      .btn-confirm {
        margin-bottom: 36px;
      }
    }

    &-info {
      animation: slideUp 0.3s linear;
      transition: 0.3s ease-out;
    }
  }

  .step-business {
    .form {
      max-width: 570px;
    }

    .row {
      animation: slideLeft 0.3s linear;
      gap: 10px;
      transition: 0.3s ease-out;

      .input {
        flex: 1;

        &.input-city {
          max-width: 170px;
        }

        &.input-code {
          align-self: end;
          display: flex;
          max-width: 100px;
        }

        &.input-phone {
          max-width: 280px;
        }
      }
    }

    .buttons {
      animation: slideUp 0.3s linear;
      transition: 0.3s ease-out;
    }
  }

  .step-focal-points {
    .form {
      max-width: 570px;

      > section {
        margin-bottom: 30px;
      }
    }

    .row {
      gap: 10px;

      .input {
        flex: 1;

        &.input-city {
          max-width: 170px;
        }

        &.input-code {
          align-self: end;
          display: flex;
          max-width: 100px;
        }

        &.input-phone {
          max-width: 280px;
        }
      }
    }

    section.country-director {
      animation: slideLeft 0.3s linear;
      transition: 0.3s ease-out;
    }

    section.registering-focal-point {
      animation: slideLeft 0.6s linear;
      transition: 0.6s ease-out;
    }

    section.clearing-focal-point,
    section.booking-focal-point {
      animation: slideLeft 0.9s linear;
      transition: 0.9s ease-out;

      .divider {
        margin-top: 24px;

        .divider-label {
          color: $color-white;
          font-size: 12px;
          margin-left: 14px;
        }
      }
    }

    .buttons {
      animation: slideUp 1s linear;
      padding-top: 10px;
      transition: 1s ease-out;
    }
  }

  .step-attachments {
    max-width: 570px;

    .row {
      &:nth-child(1) {
        animation: slideUp 0.25s linear;
        transition: 0.25s ease-out;
      }
      &:nth-child(2) {
        animation: slideUp 0.5s linear;
        transition: 0.5s ease-out;
      }
      &:nth-child(3) {
        animation: slideUp 0.75s linear;
        transition: 0.75s ease-out;
      }
      &:nth-child(4) {
        animation: slideUp 1s linear;
        transition: 1s ease-out;
      }
    }

    .buttons {
      animation: slideUp 1.25s linear;
      padding-top: 10px;
      transition: 1.25s ease-out;
    }

    .dropzone {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .step-submit {
    .form {
      max-width: 570px;
    }

    .business {
      animation: slideLeft 0.25s linear;
      transition: 0.25s ease-out;
    }

    .country-director {
      animation: slideLeft 0.5s linear;
      transition: 0.5s ease-out;
    }

    .focal-point {
      animation: slideLeft 0.75s linear;
      transition: 0.75s ease-out;
    }

    .documents {
      margin: 30px 0 20px;

      h3 {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .divider {
      margin-top: 30px;
    }

    .row {
      gap: 10px;

      .input {
        flex: 1;

        &.input-city {
          max-width: 170px;
        }

        &.input-code {
          max-width: 100px;
        }

        &.input-phone {
          max-width: 280px;
        }
      }
    }

    .buttons {
      animation: slideUp 1s linear;
      transition: 1s ease-out;
    }
  }

  .success-page {
    .container {
      background: #272727 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      padding: 28px;
      max-width: 816px;
      .icon-holder {
        text-align: center;
      }
      h3 {
        font-weight: 600; 
        font-stretch: normal; 
        font-size: 31px; 
        line-height: 42px; 
        font-family: inherit;
        letter-spacing: 0.86px;
        color: #FFFFFF;
        margin-bottom: 28px;
      }
      p {
        font-weight: normal; 
        font-stretch: normal; 
        font-size: 26px; 
        line-height: 36px; 
        font-family: inherit;
        letter-spacing: 0.72px;
        color: #FFFFFF;
        margin-bottom: 0px;
        &:first-of-type {
          margin-bottom: 20px;
        }
        i {
          font-style: italic;
        }
      }
    }
  }

  .label {
    color: $color-white;
    cursor: default;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.39px;
    margin-bottom: 10px;
    word-break: break-word;

    span {
      color: #fa5252;
    }
  }

  .field-phone {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
