@import '@assets/styles/variables.scss';

.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 40px !important;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 60px !important;
  }
}

.readOnly input {
  background-color: #b6bcc0;
}

.description {
  font-size: 14px;

  &.underline {
    margin-top: 20px;
    text-decoration: underline;
  }
}

.content {
  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .form {
    max-width: 100%;
    animation: slideLeft 0.3s linear;
    transition: 0.3s ease-out;
  }

  .row {
    gap: 10px;

    .input {
      flex: 1;

      &.input-city {
        max-width: 170px;
      }

      &.input-code {
        align-self: end;
        display: flex;
        max-width: 100px;
      }

      &.input-phone {
        max-width: 280px;
      }
    }
  }

  .validation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    input {
      outline: none;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 4px;
      border: 1px solid #ced4da;
      font-size: 14px;
      color: #000;
      text-align: left;
      min-height: 36px;
    }

    .text {
      color: #70819f;
      margin-bottom: 0;
    }

    .value {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 10px;

      .number {
        font-size: 20px;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 2px;
      }
    }
  }

  .buttons {
    animation: slideUp 0.3s linear;
    transition: 0.3s ease-out;
  }

  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    hr {
      width: 100%;
      height: 1px;
      border: none;
      background-color: #70819f;
    }

    span {
      margin: 0 10px;
    }
  }

  .log-in {
    border: 1px solid #3a89f7;
    color: #3a89f7;
  }
}
