@import '@assets/styles/variables.scss';

.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 40px !important;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 60px !important;
  }
}

.description {
  font-size: 14px;

  &.underline {
    margin-top: 20px;
    text-decoration: underline;
  }
}

.content {
  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .form {
    width: 100%;
    animation: slideLeft 0.3s linear;
    transition: 0.3s ease-out;
  }

  .row {
    gap: 10px;

    .input {
      flex: 1;

      &.input-city {
        max-width: 170px;
      }

      &.input-code {
        align-self: end;
        display: flex;
        max-width: 100px;
      }

      &.input-phone {
        max-width: 280px;
      }
    }
  }

  .buttons {
    animation: slideUp 0.3s linear;
    transition: 0.3s ease-out;
  }

  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    hr {
      width: 100%;
      height: 1px;
      border: none;
      background-color: #70819f;
    }

    span {
      margin: 0 10px;
    }
  }

  .sign-up {
    border: 1px solid #3a89f7;
    color: #3a89f7;
  }
}
