@import '../../assets/styles/variables.scss';

.content {
  color: $color-white;
  padding: 20px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    padding: 40px;
  }

  @media only screen and (min-width: 992px) {
  }

  @media only screen and (min-width: 1200px) {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    align-items: start;
    padding: 60px 80px;
    overflow-x: auto;
  }

  h2 {
    color: $color-white;
    font-size: 24px;
    letter-spacing: 0.67px;
    line-height: 33px;
    margin-bottom: 30px;
  }

  h4 {
    color: $color-white;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    text-decoration: underline;
  }
}
